
  .simple-keyboard {
    max-width: 40em;
  }
  
  .numberKeyboard {
    max-width: 25em;
  }
  
  .simple-keyboard.hg-theme-default.myTheme {
      background-color: transparent !important;
      margin: auto;
  }

  div.keyboardButtonClass {
    background-color: rgb(153, 153, 153) !important;
  }

  div.keyboardButtonClass span {
    color: white;
  }
